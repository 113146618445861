.indicators-container {
    /*max-width: 600px;*/
    margin: 0 auto;
    margin-top: 20px;
}

.indicator-content {
    margin-bottom: 30px;
    padding: 20px 15px 20px 15px;
    letter-spacing: .01em;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.indicator-question {
    font-weight: 500;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .indicator-question {
        font-size: 18px;
    }
}

.vote-button {
    border-radius: 15px;
    margin-right: 15px;
    color: #40a9ff;
    border-color: #40a9ff;
}

.vote-button:hover {
    color: #1890ff;
    border-color: #1890ff;
}

.vote-button[disabled], .vote-button[disabled]:hover, .vote-button[disabled]:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background-color: #fff;
    opacity: 0.4;
}

.separator {
    margin-left: 10px;
    margin-right: 10px;
    color: #8899A6;
}

.indicator-creator-info {
    margin-left: 58px;
    margin-bottom: 10px;
    height: 58px;
}

.indicator-creator-info .creator-link {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.indicator-creator-info .creator-link:hover .indicator-creator-name {
    color: #1890ff;
    text-decoration: underline;
}

.indicator-creator-avatar {
    float: left;
    margin-top: 3px;
    margin-left: -58px;
    position: absolute;
    width: 48px;
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
}

.indicator-creator-avatar > * {
    line-height: 48px;
    font-size: 18px;
}

.indicator-creator-name {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 4px;
    display: inline-block;
    margin-right: 8px;
}

.indicator-creator-username {
    color: #657786;
    font-size: 15px;
    display: inline-block;
}

.indicator-creation-date {
    display: block;
    color: #657786;
    font-size: 13px;
    margin-top: 2px;
}

.indicator-card {
    margin: 20px 15px 20px 15px;
}

.form-inputs {
    padding: 5px 15px 5px 15px;

}